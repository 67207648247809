import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [laravelVersion, setLaravelVersion] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the Laravel version from the backend
    axios.get('https://sistemaking.com') // Update with your backend URL
      .then((response) => {
        setLaravelVersion(response.data); // Set the version data
      })
      .catch((err) => {
        console.error('Error fetching Laravel version:', err);
        setError('Failed to fetch Laravel version.');
      });
  }, []);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Laravel Version App</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {laravelVersion ? (
        <pre style={{ background: '#f9f9f9', padding: '10px', border: '1px solid #ccc' }}>
          {JSON.stringify(laravelVersion, null, 2)}
        </pre>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;
